$cellsToHide: (2, 5, 6, 8, 9, 11);

.search_patients-table {
  thead {
    tr {
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      height: 40px;
      text-transform: uppercase;
    }
    th {
      background: #546e7a;
      &:not(.t_center) { 
        text-align: left;
      }
      &.t_left {
        text-align: right;
      }
      &:last-child {
        padding-right: 24px;
      }
      @media screen and (max-width: 991px) {
        @each $cell in $cellsToHide {
          &:nth-child(#{$cell}) {
            display: none;
          }
        }
      }
      @media screen and (min-width: 991px) {
        min-width: 100px;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }

  tbody {
    background: #fff;
    font-size: 14px;
    color: #424242;
    tr {
      height: 54px;
      border: 1px solid #e3e3e3;
      cursor: pointer;
      transition: transform 150ms ease-in;
      &:not(:first-child) {
        border-top: none;
      }
      &.active {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
      }
      &:hover {
        transform: scale(1.02);
      }
    }
    td {
      &:first-child {
        width: 54px;
      }
      @media screen and (max-width: 991px) {
        @each $cell in $cellsToHide {
          &:nth-child(#{$cell}) {
            display: none;
          }
        }
      }
      &.single-row {
        text-align: center;
      }
    }
    .status-holder {
      border: 2px solid #546e7a;
      border-radius: 2px;
      font-size: 12px;
      padding: {
        top: 4px;
        bottom: 4px;
      }
      max-width: 100px;
      text-align: center;
    }
    .action-btn_holder { 
      min-width: 50px;
      padding-right: 18px;
      .action-btn-svg {
        height: 20px;
        width: 20px;
        fill:#546e7a;
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
     }
  }
  .user-icon_block {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      height: 16px;
      width: 12px;
      fill: #546e7a;
    }
  }
}
