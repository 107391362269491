.input-holder {
  position: relative;
}

.login_form-error {
  font-size: 10px;
    color: #BA1717;
    position: absolute;
    left: 15px;
    top: 105%;
    font-weight: 600;
}