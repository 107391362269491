.video_call-box {
  color:  $text-color_primary;
  &.card-box { margin-bottom: 20px; }
  .inner-holder {
    max-width: 350px;
    padding: 90px 0 100px;
    text-align: center;
  }
  .call_info {
    margin-bottom: 24px;
    p {
      margin: 0;
      & + p { font-size: 14px; }
    }
  }
  .call_countdown {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 40px;
  }
}

.chat_box {
  .tabs_container {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    li {
      &.active { color: $primary-color; }
      &:not(:last-child) { margin-right: 32px; }
    }
  }
  .chat_messages-list {
    &.no-borders{
      border: none;
    }
  }
}

.chat_message-box {
  margin-top: 10px;
  label {
    display: block;
    color: $text-color_primary;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .inner-holder {
    border: 1px solid $inputs-border-color;
    border-radius: 2px;
    position: relative;
  }
  .chat_messages-list {
    padding-right: 145px;
  }
  .btns-holder {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .send-btn {
    background: $primary-color;
    border-radius: 32px;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    padding: 7px 20px;
  }
  .attachment-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg-color_primary;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    .attachment {
      height: 17px;
    }
  }
}