.AuthForm {
  background: #ffffff;
  padding: 20px 28px;
  box-shadow: 0 0 60px 12px rgba(0,0,0,.3);
  border-top: 6px solid var(--color-primary);
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
  }
  input { width: 100%; }
}
.AuthForm__title {
  margin-bottom: 40px;
}
.AuthForm__group {
  margin-bottom: 10px;
}
.AuthForm__group_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AuthForm__submit {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  font-weight: 400 !important;
  color: #fff;
}
.AuthForm__forget-password {
  font-size: 10px;
  color: var(--text-color-dark);
  text-decoration: underline;
  &:visited {
    color: var(--text-color-dark);
  }
}
.AuthForm__image {
  width: 200px;
  height: auto;
  margin-left: 15px;
}

.login_container {
  display: flex;
  height: 100%;
}

.login_wrapper {
  align-self: flex-start;
}

.login_title {
  padding: 50px 0;
  text-align: center;
  font-size: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.login_card-box {
  background: #ffffff;
  border-top: 3px solid #71aa33;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  padding: 16px;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out;
  @media screen and (min-width: 500px) {
    width: 565px
  }
}

.login_form-holder {
  position: relative;
  min-height: 260px;
  .login_lang-switcher {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.login_card-box h3 {
  margin-bottom: 24px;
}

.login_input-holder {
  margin-bottom: 30px;
  letter-spacing: 0.6px;
  position: relative;
  label {
    color: #6c757d;
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }
  input {
    color: #424242;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 6px;
    width: 100%;
  }
  &.field_error {
    input { border-color: #BA1717; }
    span {
      font-size: 10px;
      color: #BA1717;
      position: absolute;
      left: 0;
      bottom: -15px;
      font-weight: 600;
    }
  }
}

.login_btns-holder button {  
  button {
    border-radius: 4px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;  
    background: #529e00;
    color: #ffffff; 
    height: 36px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    color: #424242;
  }
}

.login_switcher-holder {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.login_switcher-btns {
  display: flex;
  flex:  1 1 auto;
  span {
    width: 70px;
    border-radius: 4px;
    border: 1px solid #529e00;
    padding: 2px;
    margin-left: 10px;
    color: #6c757d;
    cursor: pointer;
    &.active {
      background-color: #529e00;
      color: #ffffff;
    }
  }
}

.min-height-wrapper {
  min-height: 170px;
}


.login-title {
  font-size: 16px;
  font-weight: 600;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}