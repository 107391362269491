.play-button {
  padding: 0 1em;
  width: auto;
  min-width: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:20px;
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__label {
    padding-left: 0.5em;
  }
}
