.generate-pdf {
  .header0 {
    font-size: 24px;
    font-weight: bold;
  }
  .header1 {
    font-size: 22px;
    font-weight: bold;
  }

  .header2 {
    font-size: 18px;
    font-weight: bold;
  }

  .header3 {
    font-size: 14px;
  }

  .vertical-margin {
    margin: 10px 0;
  }
  .margin-top {
    margin-top: 20px!important;
  }
  .vertical-padding {
    padding: 10px 0;
  }

  h1, h3, h2, p, span, div {
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif!important;
  }

  .risk-indicator {
    font-weight: 600;
    font-size: 14px;
    &.low {
      color: #529e00;
    }
    &.high {
      color: #BA1717;
    }
    &.moderate {
      color: #ffa600;
    }
    &.minimalRisk {
      color: #529e00;
    }
    &.mildRisk {
      color: #ffa600;
    }
    &.moderateRisk {
      color: #ffa600;
    }
    &.moderateSeverRisk {
      color: #BA1717;
    }
    &.severRisk {
      color: #BA1717;
    }
  }

  .patient-info {
    font-size: 14px;
    //display: flex;
    //flex-direction: row;
    //margin-bottom: 0;
    //margin-top: 10px;

    span {
      margin-bottom: 5px;
    }
  }

  .cognitive-results-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cognitive-results-item-wrapper {
    margin: 3mm 3mm 5mm 3mm;

    .cognitive-date {
      margin-bottom: 2mm;
      span {
        font-size: 12px
      }
    }

    .cognitive-results-item-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .cognitive-results-item {
      width: 90mm;
      position: relative;
      .cognitive-results-suspect {
        position: absolute;
        bottom: 5px;
        left: -5px;
        font-size: 10px;
      }
    }

    .cognitive-score {
      margin-right: 10mm;
    }
    .results-explanation {
      font-size: 10px;
    }
  }

  .doctor-info {
    display: flex;
    flex-direction: column;
    margin-top: 5mm;
  }

  .summary-wrapper {
    .recommendation-item {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      .recommendation-doctor-info {
        border-radius: 4px;
        background: #f4f6f9;
        padding: 8px 5px;
        font-size: 12px;
      }

      .recommendation-text-wrap {
        background: #fff;
        border: 2px solid #e0e0e0;
        border-radius: 1px;
        padding: 5px;
        .recommendation-text {
          font-size: 12px;
        }
      }
    }
  }

  .question-answer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    //border-radius: 2px;
    h3 {
      margin-top: 5px;
    }
    .answers-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      .answer-item:not(:last-child) {
        border-right: 1px solid #e4e4e4;
        padding-right: 12px;
      }
      .answer-item {
        margin: 5px 5px 0 5px;
        font-size: 12px;
      }
    }
    h3 {
      margin-bottom: 0px;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .questionnaire-wrapper {
    .anamnesis-title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      h1 {
        margin-right: 20px;
        margin-bottom: 5px;
      }
      .anamnesis-title-date {
        margin-left: 20px;
        font-size: 12px;
      }
      .risk-for-pdf {
        display: flex;
        align-items: center;
        .common-title {
          margin: 0;
        }
        .warning-info {
          margin-left: 5px;
        }
        .risk-warning-mark {
          position: initial !important;
        }
      }
    }
    .anamnesis-section-wrapper {
    }
  }
  .spoiler_body {
    padding: 0;
  }

  .cognitive-tests-wrapper {
    .cognitive-tests-score {
      .tests-score-title {
        font-weight: 600;
        font-size: 14px;
      }
      .tests-score-points, .tests-score-date {
        font-size: 12px;
        font-weight: 900;
      }
    }
    .diagnostic_test_body {
      margin-bottom: 3px;
      .spoiler_box:not(last-child) {
        margin-bottom: 2px;
        .spoiler_header {
          padding: 5px;
          align-items: center;
          h3, span {
            font-size: 12px;
          }
        }
      }
    }
    .cognitive-explanation {
      p {
        margin: 0;
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
      .left {
        flex: 4;
        border: 2px dashed #585555;
        padding: 10px;
        margin-right: 5px;
      }
      .right {
        flex: 2;
        border: 2px dashed #585555;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    }
  }

  .course-сhart_wrapper {
    width: 100px;
    margin-left: auto;
  }

  .survey-tests-wrapper {
    .survey-test-top-content {
      //display: flex;
      //flex-direction: row;
      //justify-content: space-between;
      //align-items: flex-start;
      border-bottom: 1px solid #E3E3E3;
      .test_general-info_box {
        padding: 10px;
        //margin-right: 20px;
        border: none;
        .common-title {
          margin-bottom: 0;
        }
        .info-body {
          margin-bottom: 8px;
        }
      }
      .preliminary-results_box {
        margin-bottom: 0;
        //padding-bottom: 0;
        border: none;
        padding: 10px 20px;
        .preliminary-results-wrap {
          margin:0;
          padding:0;
          list-style-type: none;
          display: table;
          table-layout: fixed;
          width: 100%;
          .inline-block {
            display: table-cell;
            text-align: left;
            //vertical-align: middle;
          }
        }
      }
    }
    .disfunctions_analize {
      margin-bottom: 0;
    }
    .brains-analize-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 28px;
      .brain_map_box {
        display: block;
        .brain_layer {
          .brain_point-item {
            box-shadow: none;
            border: 1px solid #ccc;
            flex: 0 0 22px;
            height: 22px;
            width: 22px;
            span {
              font-size: 9px;
            }
          }
          &--top {
            width: 80%;
          }
          &--profil {
            .additional-wrapper {
              margin-top: 80px;
              padding: 0;
              position: initial;
              .BrainProfil {
                height: 75%;
                position: initial;
              }
              .brain_points-list {
                height: 75%;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .thermometer_item_title {
      box-shadow: none;
    }
    .survey-diagnostic-header {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      :nth-child(2) {
        width: 30%;
      }
      span {
        width: 25%;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .survey-diagnostic-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 10px 0 10px;
      ul:nth-child(2) {
        width: 30%;
      }
      .survey-diagnostic-list {
        height: 100%;
        width: 25%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        .brain-zone_color {
          font-size: 8px;
        }
        .brain-zone_title {
          font-size: 12px;
          padding-right: 6px;
        }
      }
    }
    .disfunctions_legend {
      text-align: center!important;
      div {
        display: inline-grid!important;
      }
    }
  }
}

/* print styles */
@media print {
  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }

  .no-print {
    display: none;
  }

  .break_inside, .break_after, .break_before {
    &--avoid_page {
      break-inside: avoid-page;
    }

    &--avoid {
      break-inside: avoid;
    }

    &--auto {
      break-inside: auto;
    }

    &--avoid_column {
      break-inside: avoid-column;
    }

    &--avoid_region {
      break-inside: avoid-region;
    }
  }

  .break_before {
    &--avoid_page {
      break-before: avoid-page;
    }

    &--avoid {
      break-before: avoid;
    }

    &--page {
      break-before: page;
    }

    &--auto {
      break-before: auto;
    }

    &--avoid_column {
      break-before: avoid-column;
    }

    &--avoid_region {
      break-before: avoid-region;
    }
  }

  /* print.css */
  table, img, svg {
    break-inside: avoid;
  }

  .diagnostic_question_details .risk-notes {
    flex: 4;
  }
  .diagnostic_question_details .risk-notes.score {
    flex: 2;
  }
  .spoiler_body {
    padding: 0;
  }
}

@page {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }
}
