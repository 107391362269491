@import "../search/parts/search-block";
@import "../search/parts/search-controls";
@import "../search/parts/search-filters";
@import "../search/parts/search-table";

.translation-box {
  margin-bottom: 20px;

  .search-block_results-counter {
    .flex:first-child {
      margin-right: 24px;
    }
    .circle {
      display: inline-block;
      margin-right: 18px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      &--red {
        background-color: #ed9393;
      }
      &--orange {
        background-color: #f9af40;
      }
    }
  }

}
.translation_table-box {
  @media screen and (min-width: 1200px) {
    max-width: 1400px;
    max-height: calc(100vh - 190px);
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .translation_uploader-area {
    border: 2px dashed;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    flex-direction: column;
    input {
      height: 1px;
      width: 1px;
      visibility: hidden;
    }
    .translation_uploader-title {
      &:first-child {
        margin-bottom: 18px;
      }
      .uploader-link {
        text-decoration: underline;
        &:hover {
          color: blue;
        }
      }
    }
  }

  .translation-item {
    padding: 9px 0px;
    background-color: #f4f6f9;
    cursor: pointer;
    &:nth-of-type(2n) {
      background-color: $white;
    }
    font-size: 14px;
    .translation-key {
      i {
        font-weight: bold;
      }
    }
    .translation-value {
      &.hoverable {
        text-decoration: underline;
      }
      &.with-margin {
        margin: 12px 0;
      }
    }
    .indicate {
      &--missing {
        background-color: #ed9393;
      }
      &--not_translated {
        background-color: #f9af40;
      }
    }
  }
}

.translation_filters-row {
  display: flex;
  width: 100%;
  > .flex-container {
    width: 100%;
    justify-content: space-between;
  }
  @media screen and (min-width: 991px) {
    justify-content: flex-end;
    > .flex-container {
      width: auto;
    }
  }
    .button-box {
      font-size: 12px;
      .icon-holder {
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
}