@import "src/assets/scss/utils/colors";

.side-note {
  background: $white;
  border: 2px solid $border-color_primary;
  border-radius: 2px;
  padding: 10px;
  &__body {
    position: relative;
  }
  &__header {
    padding-top: 10px;
    margin: 0 -5px;
    font-size: 13px;
    font-style: italic;
  }
  &__text {
    padding-right: 25px;
  }
  &__edit-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    fill: $text-color_additional;
  }
}
