$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$map-grid-props: (
  "": 0,
  "sm": $grid__bp-sm,
  "md": $grid__bp-md,
  "lg": $grid__bp-lg,
  "xl": $grid__bp-xl
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    @for $i from 1 through $grid-cols {
      $num: ($i/$grid-cols) * 100%;
      &-#{$modifier}-#{$i} {
        flex: 0 0 #{$num};
        max-width: #{$num};
      }
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  // max-width: 1000px;
  &:not(.collapse) {
    margin: 0 -10px;
  }
}

.flex-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding: {
    right: 10px;
    left: 10px;
  }
  &--shrink {
    flex: 0 0 auto;
  }
  &--stretch {
    flex: 1 1 0px;
  }
  .collapse & {
    padding: 0;
  }
}

.item {
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == "") {
    $modifier: "xs";
  }
  @include create-mq($breakpoint - 1, "max") {
    .hidden-#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, "min") {
    .hidden-#{$modifier}-up {
      display: none !important;
    }
  }
}

.overflow-y {
  overflow-y: scroll !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.p-0 {
  padding: 0 !important;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.h-80 {
  height: 100%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% 
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-5 {
  padding-left: 5px;
}


.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.ph-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.p-10 {
  padding: 10px;
}

.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}

@include create-mq($grid__bp-lg, "min") {
  .pv-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include create-mq($grid__bp-md, "min") {
  .pv-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
