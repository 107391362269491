.selector-box {
  position: relative;
  .accounts_filter-box  & { min-width: 115px; }
  .patients_filter-box & { min-width: 175px; }
}

.selector_selected-block {
  background: $white;
  border: 1px solid $ordinary-grey;
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text-color_primary;
  font-size: 14px;
  height: 28px;
  border-radius: 28px;
  cursor: pointer;
}
