.test_summary-charts {
  h3 {
    font-size: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}
.cognitive-chart_box { margin-bottom: 10px; }
.cognitive_progress-chart {
  border: 1px solid $inputs-border-color;
  border-radius: 8px;
  height: 8px;
  width: 100%;
  position: relative;
  margin-bottom: 6px;
  .progress-line {
    background: linear-gradient(148.43deg, #92DE50 0%, #72AB41 100%);;
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
  }

}

.risk_progress-pointer {
  height: 18px;
  width: 100%;
  position: relative;
  .pointer {
    height: 18px;
    width: 18px;
    transform: translateX(-50%) rotate(90deg);
    position: absolute;
  }
}

.risk_progress-legend {
  font-size: 12px;
  position: relative;
  .info-icon {
    margin-left: 12px;
    height: 24px;
    width: 24px;
    fill: $light-grey;
  }
}

.info_list {
  display: none;
  position: absolute;
  top: -2em;
  left: 50%;
  transform: translate3D(-50%, 60%, 0);
  min-width: 300px;
  padding: 5px;
  color: $black;
  border: 1px solid $ordinary-grey;
  border-radius: 4px;
  background: $white;
  font-size: 14px;
  text-align: left;
  z-index: 10;
  .risk_progress-legend:hover & {
    display: block;
  }
}

.risk_progress-score-legend {
  height: 18px;
  width: 100%;
  position: relative;
  font-size: 10px;
  span {
    text-align: center;
  }
}

.risk_progress-chart {
  border: 1px solid $inputs-border-color;
  border-radius: 8px;
  height: 16px;
  width: 100%;
  position: relative;
  margin-bottom: 6px;
  .progress-line {
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .progress-line-cognitive {
    background: linear-gradient(148.43deg, $error-color 0%, $thermometer-color-yellow 65%, $success-color 100%);
    &--revert {
      background: linear-gradient(148.43deg, $success-color 0%, $thermometer-color-yellow 35%, $error-color 100%);;
    }
  }
  .progress-line-family {
    background: linear-gradient(90deg, $success-color 0%, $thermometer-color-pale-yellow 30%, $thermometer-color-pale-yellow 70%, $error-color 100%);;
  }
  .progress-line-border {
    height: 100%;
    width: 2px;
    background-color: $black;
    position: absolute;
    left: 60px;
    border-radius: 3px;
    z-index: 10;
  }
  .progress-line-hole {
    background-color: $light-grey;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
  }
}

.cognitive_chart-data {
  .bold { font-weight: 600; }
  span { font-size: 12px; }
}

.cognitive_clock-icon {
  height: 12px;
  width: 12px;
  &:not(last-child) { margin-right: 6px; }
}
