.patient-info {
  margin-bottom: 30px;
  overflow: visible;

  .card_inputs-box {
    margin-bottom: 38px;
    padding: 0px 20px 0px 20px;
  }

  .btns-holder {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 576px) {
    .btns-holder {
      justify-content: right;
    }
  }
}

.patient-history {
  th:last-child {
    display: none;
  }

  td:last-child {
    display: none;
  }

  @media screen and (min-width: 576px) {
    margin-top: 50px;
    th:last-child,
    td:last-child {
      display: table-cell;
    }
  }
}

.btns-holder {
  margin-top: 12px;
  justify-content: right;
  text-align: right;
}

.patients-title_icon-holder {
  svg {
    fill: #fff;
    height: 12px;
    width: 12px;
  }
}

.patients-detail_icon-holder {
  svg {
    fill: #fff;
    height: 16px;
    width: 16px;
  }
}

.search_table-box {
  .selector_list-block {
    transform: translateX(-60%);
    top: -5px;
  }
}

.search_download-box {
  .selector_list-block {
    left: auto;
    right: 0;
  }
}

.prepare-for-pdf {
  height: 3000px;
  overflow-y: scroll;
}

.brain-category {
  &.frontal-pt {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  margin: 16px 0px;

  .test_description_title {
    margin-bottom: 5px;
  }

  .title-points {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}

.paginator-wrapper {
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.document-iframe-holder {
  iframe {
    width: 100%;
  }
}

.lastSentTextMessageHolder {
  align-self: end;
}
