.DayPicker,
.DayPicker-Months,
.DayPicker-Month {
  width: 100%;
}
.DayPicker-Month {
  margin: 0;
}
.DayPicker-NavButton {
  top: 0;
  right: 0;
}
.DayPicker-Caption {
  padding: 0;
  margin-bottom: 16px;  
  > div { font-size: 16px; }
}
.DayPicker-Weekdays,
.DayPicker-Body {
  font-size: 12px;
}
.DayPicker-Weekdays {
  border: 1px solid #E0E0E0;
  border-bottom: none;
  color: #546e7a;
}
.DayPicker-Body {
  border: 1px solid #E0E0E0;
  border-top: none;
  color: #424242;
}
.DayPicker-Day {
  padding: 4%;
}
.DayPicker-NavButton {
  background: none;
  position: absolute;
  padding: 6px; /* Arrow size */
          box-shadow: 1px -1px 0 1px #455A64 inset;
  -webkit-box-shadow: 2px -2px #455A64 inset;
  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  margin: 0;
  top: -14px;
  width: auto;
  height: auto;
  &--prev {
    transform:rotate(45deg);
    right: 45px;
  }
  &--next {
    transform:rotate(225deg);
    left: calc(100% - 10px);
  }
  &:focus {
    outline: none;
  }
}
.DayPickerInput {
  width: 100%;
}