$answer-min-width: 120px;

.spoiler_box {
  background: #fbfbf8;
  &:not(last-child) { 
    margin-bottom: 8px;
    margin-left: 15px; 
  }
}
.risk-warning-mark {
  width: 24px;
  height: 24px;
}

.anamnesis-section-title {
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}

.spoiler_header {
  border-radius: 4px;
  background: $bg-color_primary;
  padding: 8px 14px;
  font-size: 16px;
  cursor: pointer;
  &.isOpen {
    background: $dark-blue;
    color: $white;
  }
  &.more-space {
    padding: 16px 24px;
  }

  .fixed-title {
    @media screen and (min-width: 400px) {
      min-width: 350px;
    }
  }

  .anamnesis-section-title {
    min-width: 100%;
    padding-bottom: 5px;
    @media screen and (min-width: 577px) {
      min-width: unset;
      padding-bottom: 0;
    }
  }

  .risk-warning-mark {
    left: 0;
    top: -2px;
  }
  .risk-indicator {
    font-weight: 600;
    font-size: 14px;
    &.low {
      color: #529e00;
    }
    &.high {
      color: #BA1717;
    }
    &.moderate {
      color: #ffa600;
    }
    &.minimalRisk {
      color: #529e00;
    }
    &.mildRisk {
      color: #ffa600;
    }
    &.moderateRisk {
      color: #ffa600;
    }
    &.moderateSeverRisk {
      color: #BA1717;
    }
    &.severRisk {
      color: #BA1717;
    }
  }
}

.spoiler_body { padding: 10px; }

.diagnostic_question_body {
  background: $white;
  border: 2px solid $border-color_primary;
  border-radius: 2px;
  //padding: 14px;
  .inner-wrapper {
    &--actions {
      flex: 0 0 110px;
      max-width: 110px;
    }
    &--answers {
      width: 100%;
    }
  }

  .g_btn {
    font-weight: 400;
    height: 23px;
    &:not(.g_btn--outlined) { width: 100%; }
  }

  .block-title {
    margin-bottom: 12px;
    h4 {
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  .block-picture {
    margin: 0 auto;
    .pic-holder {
      margin: 20px auto 15px;
      height: 230px;
      width: 230px;
    }
    .btn-holder { text-align: center; }
  }
  .play-btn {
    color: $text-color_primary;
    border: 1px solid;
    padding: 0 12px;
    font-size: 12px;
    width: auto;
    .play-icon {
      height: 8px;
      width: 10px;
      margin-right: 5px;
    }
  }

  .question-hint {
    display: flex;
    position: relative;
    .info-icon {
      cursor: pointer;
      margin-left: 12px;
      height: 18px;
      width: 18px;
      fill: $dark-blue;
    }
  }
  .warning-info {
    color: red;
  }
}
.info_list {
  .question-hint:hover & {
    display: block;
  }
}

.diagnostic_question_details {
  .answer-item {
    font-size: 14px;
    min-width: $answer-min-width;
    max-width: calc(100% - #{$answer-min-width});
    //margin-bottom: 12px;
    &:not(:last-child) {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid $border-color;
    }
    dt {
      font-size: 12px;
      margin-bottom: 6px;
    }
    dd {
      color: #000;
    }
  }
  .risk-notes {
    flex: 1 0 400px;
    font-size: 12px;
    margin-bottom: 12px;
    &.score {
      flex: 0 0;
      &.laila-case {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
      }
      &.laila-case dd .risk-indicator-wrapper .risk-indicator {
        min-width: 50px;
      }
      &.laila-case dd .risk-indicator-wrapper .risk-indicator .risk-interval {
        font-size: 12px;
      }

      &.laila-case dd .risk-indicator-wrapper .risk-indicator {
        &:first-child {
          display: flex;
          align-items: center;
        }
      }
    }
    &:not(:last-child) {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid $border-color;
    }
    dt {
      font-size: 12px;
      margin-bottom: 6px;
    }
    dd {
      color: #545e6f;
      a {
        text-decoration: underline;
        color: blue;
      }
      .risk-indicator-wrapper {
        margin-bottom: 12px;
        .risk-indicator  {
          margin-right: 12px;
          font-size: 11px;
          min-width: 65px;

          .risk-interval {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.text-value-risk-score {
  min-width: 150px;
  @media screen and (min-width: 992px) {
    padding-left: 30px;
  }
  span {
    font-size: 14px;
  }

}

.svg-group {
  flex: 0 0 100%;
  text-align: center;
}

.question_edit-btn {
  display: flex;
  button.g_btn {
    max-width: 145px;
    &.incorrect {
      background-color: #f4f6f9;
    }
  }
}

.answer_status-text {
  &.on-hover{ display: none; }
  .g_btn:hover & {
    display: none;
    &.on-hover {
      display: block;
    }
  }
  &.incorrect {
    color: #545e6f;
  }
}
