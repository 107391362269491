
.svg-block path {
  fill-opacity: 0;
  stroke: #000;
  stroke-width: 1;
}

.svg-container {
  text-align: center;
}

@keyframes draw {
  to {
      stroke-dashoffset: 0;
  }
}

.drawing-line {
  height:1px;
  width:40%; 
  background-color: #e4e4e4;
  border: 1px solid #E3E3E3;
}
