.profile_perfomans-chart {
  padding: 20px;
}
.profile_progress-box {
  .progress_chart {
    position: relative;
    margin-right: 10px;
  }
  .progress {
    background: #e6e7e8;
    height: 84px;
    width: 84px;
    position: relative;
    border-radius: 50%;
    &:before {
      content: "";
      background: #fff;
      border-radius: 50%;
      height: 64px;
      width: 64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
    &:after {
      content: "";
      background: #94c85b;
      z-index: 5;
    }
  }
  .value {
    font-size: 20px;
    color: #4a4a4a;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
  }
  .progress_legend {
    max-width: 85px;
  }
  .legend_description {
    margin-bottom: 10px;
    > span {
      display: block;
      font-size: 10px;
      line-height: 1.3;
    }
  }
  .perfomance-values {
    color: #424242;
    font-size: 14px;
    dt:after {
      content: "/";
      display: inline-block;
    }
  }
}
