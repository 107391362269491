.voucher-code {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.statistic-item {
  text-align: left;
  margin-right: 24px;
  width: 20%;
}

.patient-section-wrapper {
  transition: all 0.2s ease-in-out;
  @media (min-width: 1200px) {
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    overflow-y: scroll;
  }
}


.translation-link {
  color: #529e00;
  text-decoration: underline;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #d3d3d3a6;
  }
}
