.svg-holder {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
}

.coding-holder {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  flex-direction: column;
}

.Arrow__row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.Arrow__item {
  font-size: 20px;
  color: #747474;
  border: 1px solid #747474;
  width: 35px;
  height: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Arrow__item_wrong {
  background-color: #db314b;
  color: #ffffff
}

.Arrow__item-img {
  height: 30px;
}

.Arrow__legend {
  border: 1px solid #747474;
  color: #747474;
  width: 90px;
  font-size: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.coding-arrow-1 {
  transform: rotate(270deg)  
}
.coding-arrow-2 {
  transform: rotate(315deg)
  
}
.coding-arrow-3 {
  transform: rotate(0deg)
}
.coding-arrow-4 {
  transform: rotate(45deg)
  
}
.coding-arrow-5 {
  transform: rotate(90deg)
  
}
.coding-arrow-6 {
  transform: rotate(135deg)
  
}
.coding-arrow-7 {
  transform: rotate(180deg)
  
}
.coding-arrow-8 {
  transform: rotate(225deg)
}
.coding-arrow-9 {
  border: 2px solid #747474;
  border-radius: 50%;
  transform: rotate(270deg);
}
