.radio-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: center center no-repeat;
    background-color: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;
    transition: all 200ms ease-in;
}

.radio-icon.selected {
    background-color: #529e00;
    background-size: 75% 75%;
}
