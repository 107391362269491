@font-face {
  font-family: "Avenir";
  src: url("../../fonts/avenir/AvenirNextCyr-Regular.eot");
  src: url("../../fonts/avenir/AvenirNextCyr-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/avenir/AvenirNextCyr-Regular.woff") format("woff"),
    url("../../fonts/avenir/AvenirNextCyr-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

strong {
  font-weight: bold;
  color: black;
}
