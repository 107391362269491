.contact-card {
  padding:40px;
  min-height:400px;
  min-width:300px;
  margin-top:50px;
  text-align: center;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    align-self: center;
    margin-left:auto;
    margin-right:auto;
  }
  &_image-wrapper {
    width: 150px;
    height: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &_image {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding-bottom:20px;
  }
}
