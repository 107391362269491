.flex {
  display: flex;
  &--wrap {
    flex-wrap: wrap;
  }
  &--nowrap {
    flex-wrap: nowrap;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  &--stretch {
    flex: 1 1 0;
  }
  &--shrink {
    flex: 0 0 auto;
  }
  &--grow {
    flex: 1 0 auto;
  }
  &--fullwidth {
    flex: 0 0 100%;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-end {
    justify-content: flex-end;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-stretch {
    align-items: stretch;
  }
  &-end {
    align-items: flex-end;
  }
}

.align-content {
  &-start {
    align-content: flex-start;
  }
  &-center {
    align-content: center;
  }
  &-stretch {
    align-content: stretch;
  }
  &-end {
    align-content: flex-end;
  }
}
