@import "src/assets/scss/utils/colors";

@mixin common-input-field() {
  color: $text-color_primary;
  border: 1px solid $text-color_secondary;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-color_additional;
  }
}
