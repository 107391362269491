.show-archived-tests {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  span {
    border-radius: 4px;
    border: 1px solid #529e00;
    padding: 6px;
    cursor: pointer;
    background-color: #529e00;
    color: #ffffff;
  }
}
