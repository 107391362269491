.Record {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding:20px;
  margin:20px;
  padding-right:20px;
  padding-left:20px;
  margin-bottom:50px;
}

.Type11__edit-row {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Record__svg {
  border:none;
}

.Record__controls {
  width: 660px;
  height: 50px;
  color: #484848;
  display: flex;
  justify-content: space-around;
  padding:10px;
  background-color: white;

}

.Record__controls_time {
  margin: auto 0;
  color: #484848;
  padding:0px;
}

.Record__controls_button {
  border: none;
  background-color: #61d73b;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Record__title-text {
  padding: 10px 0;
  font-size: 16px;
}

svg text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}