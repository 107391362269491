.login-title {
  margin-bottom: 0px !important;
  font-size: 14px;
}

.login_switcher-btns span {
  width: initial;
  font-size: 14px;
  padding: 5px 10px;
}

.login_input-holder--bankid input {
    padding-right: 40px;
}

.bankid-icon {
  width: 30px;
  height: 30px;
  top: 3px;
  right: 5px;
}

.bankid-icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
