.administrative-page {
  &__aside {
    @media screen and (min-width: 768px) {
      padding-left: 15px;
    }
  }
  &__heading {
    padding: 10px 0 20px 20px;
    cursor: pointer;
    p {
      margin: 0 0 0 20px;
      font-size: 20px;
      cursor: pointer;
    }
    :hover {
      text-decoration: underline;
    }
  }
}

.float-right-LoginCodeForm {
  float:right;
  width:100%;
  text-align:center;
}