$bg-active: $primary-color_light;

.sidebar-wrapper {
  animation: fadeIn 0.5s ease-in-out;
  background: $white;
  box-shadow: 0 4px 10px rgba($black, 0.25);
  position: absolute;
  transform: translateX(-265px);
  transition: transform 0.2s ease-in-out;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow: hidden;
  &.active {
    transform: translateX(0);
  }
  @media screen and (min-width: 1500px) {
    flex: 0 0 270px;
    position: static;
    transform: translateX(0);
  }
}

.sidebar_nav-box {
  padding-left: 20px;
  margin-bottom: 10px;
}

.sidebar-wrapper.active {
  overflow-y: scroll;
}

.sidebar_logo-box {
  padding: 15px 0 25px;
  display: flex;
  justify-content: center;
  .logo_link {
    display: block;
  }
  svg {
    max-width: 180px;
    max-height: 40px;
  }

}

.sidebar_info-box {
  text-align: center;
    padding: 0px 10px 15px 10px;
    font-size: 14px
}

.nav-list {
  color: $text-color_primary;
  font-size: 14px;
  > li {
    margin-bottom: 12px;
  }
  .inner-nav {
    padding-left: 54px;
    > li:not(:last-child) {
      margin-bottom: 10px;
    }
    a { display: block; }
    li.active {
      a {
        text-decoration: underline;
      }
    }
  }
}

.nav-item {
  position: relative;
  padding: 12px 24px 12px 54px;
  line-height: 1;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
  }
  &.active {
    background: rgba($bg-active, 0.2);
    color: $primary-color;
    &:after {
      background: $primary-color;
    }
    .sidebar_nav-icon {
      fill: $primary-color;
    }
  }
  .nav_title {
    font-weight: 600;
  }
  @media screen and (min-width: 768px) {
    border-radius: 40px 0 0 40px;
  }
  @media screen and (min-width: 1100px) {
    height: 30px;
    .nav_title {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: 54px;
      display: flex;
      align-items: center;
    }
  }
}

.nav_icon-holder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
.sidebar_nav-icon {
  fill: $text-color_primary;
  width: 14px;
  height: 12px;
}

.sidebar_actions-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sidiber_action-item {
  flex: 0 0 180px;
  max-width: 180px;
  padding: 0 8px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.footer-legal-mark {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 4px;
}
