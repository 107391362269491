@import "src/assets/scss/utils/colors";

.start-page {
  &__content-wrapper {
      animation: fadeIn 0.5s ease-in-out;
    position: relative;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba($black, 0.05);
    margin: 0 20px;
    z-index:10;
    @media screen and (min-width: 480px) {
      margin: 0;
    }
    @media screen and (min-width: 768px) {
      margin-bottom:20px;
    }
  }
  &__search-input-wrapper {
    margin: 0 auto;
    padding: 20px 0;
    @media screen and (min-width: 768px) {
      width: 75%;
    }
  }
  &__search-results-wrapper {
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }
  &__create-button-wrapper {
    text-align: right;
    @media screen and (min-width: 768px) {
      position: absolute;
      right: -40px;
      top: -15px;
    }
  }
}

.greetingTitle {
  font-size: 30px;
  animation: fadeIn 0.5s ease-in-out;
}

.greetingText {
  font-size:15px;
  animation: fadeIn 0.5s ease-in-out;
}