html,
body {
  padding: 0;
  margin: 0;
}

html {
  font-family: "Avenir";
  font-size: 100%;
  font-weight: normal;
  color: $text-color_additional;
  box-sizing: border-box;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $bg-color_primary;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-size: inherit;
  font-weight: 600;
}

dl {
  text-align: left;
  margin: 0;
  dd {
    margin: 0;
  }
  &.dl--inline {
    display: flex;
    align-items: center;
  }
}

svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

textarea {
  font: inherit;
  resize: none;
}

/* print styles */
@media print {
  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }
}

@page {
  //reset styles for print
  .wrapper {
    background: initial!important;
    display: initial!important;
    height: initial!important;
    width: initial!important;

    .main-content-wrapper {
      margin: 0!important;
      padding: 0!important;
      flex: initial!important;
      max-height: initial!important;
      height: initial!important;
      display: initial!important;
      flex-direction: initial!important;
      overflow: initial!important;

      .page-wrapper {
        display: initial!important;
        flex-direction: initial!important;
        max-width: initial!important;
        overflow: initial!important;
        max-height: initial!important;
        margin: 0!important;
        padding: 0!important;
      }
    }
  }
  body {
    background: initial!important;
  }
}
