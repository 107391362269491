@import "./parts/main-info";
@import "./parts/perfomance-chart";
@import "./parts/slots-info";
@import "./parts/meetings-chart";
@import "./parts/edit-info";

.profile_doctor,
.profile_chart-box {
  margin-bottom: 16px;
}

.device-info {
  position: relative;
  font-size: 14px;
}

.device-info_list {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3D(-50%, -80%, 0);
  min-width: 200px;
  padding: 5px;
  color: #fff;
  background: #000;
  font-size: 12px;
  font-weight: 600;
  display: none;
  text-align: center;
  z-index: 10;
  .device-info:hover & {
    display: block;
  }
}