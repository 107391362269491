.search_status-selector {
  .selector_selected-block {
    height: 34px;
    border-radius: 34px;
  }
}

.search_filters-box,
.search_download-box {
  position: relative;
}

.search_download-icon {
  fill: #546e7a;
  width: 14px;
  height: 17px;
}
.search_filter-icon {
  fill: #546e7a;
  height: 10px;
  width: 15px;
}

.search_btn {
  background: #fff;
  border: 1px solid #c4c4c4;
  color: $text-color_primary;
  font-weight: normal;
  height: 34px;
  vertical-align: middle;
  text-align: left;
  &.active {
    background: #529e00;  
    color: #fff;
  }
  .icon-holder { line-height: 0; }
  &--filters {
    border-radius: 34px;
    width: auto;
    padding: {
      left: 30px;
      right: 30px;
    }
    .icon-holder {
      display: inline-block;
      margin-right: 10px;
    }
  }
  &--collapse {
    height: initial;
    border-radius: 34px;
    width: auto;
    font-size: 12px;
    cursor: pointer;
    padding: {
      left: 10px;
      right: 10px;
    }
    .icon-holder {
      display: inline-block;
      margin-right: 6px;
      transform: rotate(180deg);
    }
  }

  &--download,
  &--preliminary {
    border-radius: 50%;
    text-align: center;
    width: 34px;
  }
  &--preliminary {
    color: $text-color_additional;
  }

  &--no-borders { border: none; }
}
