.confirmation_wapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.9);
  z-index: 100;
  &.more-darker {
    background: rgba($black, 0.9);
  }
}
.confirmation_box {
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  padding: 20px;
  border-radius: 5px;
  min-width: 50vw;
}
.confirmation_message {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}
.confirmation_btns-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;  
}
.confirmation_btn {
  color: $white;
  &--confirm {
    background: #e45e5e;
  }
  &--cancel {
    background: #ccc;
  }
  &--yes {
    background: #529e00;
  }
}

.popup-pdf-content-holder {
  overflow-y: auto;
  min-width: 85vw;
  max-height: calc(90vh - 200px);
  @media screen and (min-width: 768px) {
    min-width: 700px
  }
}
