.main-tabs_list {
  flex-wrap: nowrap;
  > li {
    flex: 1 1 0px;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    > li {
      flex: 0 0 auto;
    }
  }
}

.main-tab_tab {
  border-radius: 10px;
  // color: #546e7a;
  color: $text-color_additional;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  .active > & {
    background: $white;
    color: $primary-color;
  }
  @media screen and (min-width: 768px) {
    border-radius: 38px;
    height: 38px;
    font-size: 14px;
  }
}
