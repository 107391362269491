.sub-tabs_list {
  display: flex;
  border-bottom: 1px solid $dark-grey;
  li {
    padding: 0 16px;
    &.active {
      border: {
        left: 1px solid $dark-grey;
        right: 1px solid $dark-grey;
      }
      position: relative;
      &:after {
        content: "";
        background: $bg-color_primary;
        position: absolute;
        top: 100%;
        left: 0;
        height: 2px;
        width: 100%;
      }
    }
    &:first-child {
      border-left: none;
    }
  }
}

.sub-tabs_tab {
  cursor: pointer;
  font-size: 14px;
  color: $text-color_primary;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .active > & {
    font-weight: 600;
  }
  @media screen and (min-width: 768px) {
    height: 38px;
  }
}
