@import "src/assets/scss/utils/colors";

.tooltip-icon {
  &__wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: relative;
    &:hover .tooltip-icon__message {
        display: block;
      }
  }
  &__icon {
    display: flex;
    cursor: pointer;
    height: 18px;
    width: 18px;
    fill: $dark-blue;
  }
  &__message {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    min-width: 250px;
    padding: 5px;
    color: $black;
    border: 1px solid $black;
    border-radius: 4px;
    background: $white;
    font-size: 12px;
    text-align: center;
    z-index: 10;
  }
}


