.note-popup_header {
  margin-bottom: 28px;
  font-weight: 600;
}

.note-popup_body {
  margin-bottom: 16px;
  .note-popup_info {
    font-size: 16px;
    margin: 0 0 16px;
  }
}

.note-popup_footer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 60px;
}

.note-popup_body {
  textarea {
    border: 1px solid $border-color_primary;
    border-radius: 3px;
    resize: none;
    padding: 6px 10px;
  }
}

.note-textarea {
  resize: vertical;
  max-height: 120px;
}
