.greenHills {
  animation: fadeIn 1.5s ease-in-out;
  margin:0;
  padding:0;
  z-index:-1;
  position: absolute;
  bottom:0;
  min-width:100%;
  overflow: hidden;
  margin-left:-50px;
}

@media only screen and (min-width: 650px) and (max-width: 1560px)  {
  .greenHills {
    display:none;
  }
}