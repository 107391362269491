$containerWidth: 406.41;
$containerHeight: 250.81;

@function getPercents($val, $isHorizont) {
  @if $isHorizont {
    @return ((100 * $val) / $containerWidth) * 1%
  } @else {
    @return ((100 * $val) / $containerHeight) *1%
  }  
};

@function getSKPercents($val) {
  @return ((100 * $val) / 325.55) * 1% 
};

.brain_point-item {
  &.f6 {
    left: getPercents(15, true);
    top: getPercents(108, false);
  }
  &.f7 {
    left: getPercents(30, true);
    top: getPercents(132, false);
  }
  &.f8 {
    left: getPercents(62, true);
    top: getPercents(132, false);
  }
  &.f9 {
    left: getPercents(100, true);
    top: getPercents(134, false);
  }
  &.f5 {
    left: getPercents(158, true);
    top: getPercents(110, false);
  }
  &.f4 {
    left: getPercents(105, true);
    top: getPercents(67, false);
  }
  &.f3 {
    left: getPercents(143, true);
    top: getPercents(55, false);
  }
  &.f2 {
    left: getPercents(68, true);
    top: getPercents(58, false);
  }
  &.f1 {
    left: getPercents(110, true);
    top: getPercents(35, false);    
  }
  &.f0 {
    left: getPercents(155, true);
    top: getPercents(21, false);
  }
}

.brain_point-item {
  &.pt0 {
    left: getPercents(288, true);
    top: getPercents(26, false);
  }
  &.pt1 {
    left: getPercents(313, true);
    top: getPercents(45, false);
  }
  &.pt2 {
    left: getPercents(334, true);
    top: getPercents(72, false);
  }
  &.pt3 {
    left: getPercents(350, true);
    top: getPercents(105, false);
  }
  &.pt4 {
    left: getPercents(236, true);
    top: getPercents(70, false);
  }
  &.pt5 {
    left: getPercents(268, true);
    top: getPercents(110, false);
  }
  &.pt6 {
    left: getPercents(300, true);
    top: getPercents(147, false);
  }
  &.pt7 {
    left: getPercents(215, true);
    top: getPercents(145, false);
  }
  &.pt8 {
    left: getPercents(225, true);
    top: getPercents(179, false);
  }
  &.pt9 {
    left: getPercents(151, true);
    top: getPercents(206, false);
  }
}

@for $i from 0 through 9 {
  .brain_point-item.sk#{$i} {
    $val: 32 * $i;
    left:  getSKPercents($val)
  }
}