$white: #ffffff;
$black: #000;
$bg-color_primary: #f4f6f9;

$primary-color: #529e00;
$secondary-color: #739ff4;
$primary-color_light: #d8f4c0;

$text-color_primary: #424242;
$text-color_secondary: #455a64;
$text-color_additional: #545e6f;

$light-grey: #6c757d;
$ordinary-grey: #c4c4c4;
$dark-grey: #cbd6df;

$border-color: #e4e4e4;
$border-color_primary: #e0e0e0;
$inputs-border-color: #e3e3e3;

$placeholder-color: #b2b2b2;
$label-color: $light-grey;

$error-color: #BA1717;
$success-color: #529e00;
$dark-blue: #546E7A; 

$thermometer-color-green: #92DE50;
$thermometer-color-yellow: #FFE500;
$thermometer-color-pale-yellow: #ffee90;
$thermometer-color-orange: #FA5001;
