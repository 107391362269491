$row-height: 50px;
$bd-width: 1px;

.scheduler_table-wrapper {
  display: flex;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.scheduler_days-list {
  display: flex;
  flex-direction: column;
  > li {
    flex: 0 0 $row-height;
    display: flex;
    align-items: center;
    font-size: 10px;
    white-space: nowrap;
    position: relative;
    width: 40px;
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 30px;
      width: 1000px;
      height: 1px;
      background: rgba($black, 0.1);
      z-index: 1;
    }
    &.current {
      &:after { background: #6391E9; }
    }
  }
  
}
.scheduler_time-pointer {
  position: absolute;
  left: 0;
  padding: 2px 4px;
  border-radius: 8px;
  top: calc(100% - 8px);
  z-index: 2;
  .current > & {
    background: #6391E9;
    color: $white;
  }
}

.scheduler_table {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scheduler_table_day {
  background:rgba(89, 185, 255, 0.1);
  height: calc(50px * 24);
  position: relative;
  &--three { flex: 0 0 calc(100% / 3 - 16px); } 
}
.scheduler_table_hour {
  height: 51px;
  padding: 5px;
  position: absolute;
  left: 0;
  width: 100%;
  &--empty { background: $white; }
  &--past { background: rgba(0, 0, 0, 0.1); }
}
.scheduler_meeting-card {
  border-radius: 4px;
  background: $primary-color;
  height: 100%;
  font-size: 12px;  
  font-weight: 600;
  padding: 5px;
  &.past { background: #E1E7EA; color: #6C757D; }
  &.empty { background: transparent; }
  &.future { 
    background: $primary-color;
    color: $white;
  }
}