@import "src/assets/scss/utils/mixins";

.react-tel-input {
  .input-field {
    @include common-input-field();
    border:1px solid $inputs-border-color;
    width: 300px;
    &--full-width {
      width: 100%;
    }
  }
  .country-button {
    border-radius: 4px;
    border: 0;
    top: 1px;
    bottom: 1px;
    left: 1px;
  }
  .list-dropdown {
    border-radius: 4px;
    width: 280px;
    top: calc(100% + 8px);
  }
}
