@import "src/assets/scss/utils/colors";

.search-result-table {
  background: $white;
  font-size: 14px;
  color: $text-color_primary;

  &__wrapper {
    @media screen and (min-width: 1024px) {
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &__row {
    height: 54px;
    border-bottom: 1px solid $inputs-border-color;
    cursor: pointer;
    transition: transform 150ms ease-in;

    &:first-child {
      border-top: 1px solid $inputs-border-color;
    }

    &:hover {
      transform: scale(1.02);
    }
  }

  &__cell {
    &:first-child {
      display: none;
      @media screen and (min-width: 768px) {
        display: table-cell;
        width: 54px;
      }
    }

    .user-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        height: 16px;
        width: 12px;
        fill: $dark-blue;
      }
    }
  }
}

.titled-status-header {
  height: 58px;
  .status-hearer-wrapper {
    display: flex;
    justify-content: flex-end;
    span {
      transform: rotate(-35deg);
    }
  }
}

.search-result-table th {
  text-align: left;
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  background-color: #546e7a;;
}


.statusText {
  opacity: 0;
  transition: 0.15s;
  bottom: 10px;
  position: absolute;
  min-width: 130px;
  font-size: 13px;
}

.statusCircleDiv {
  text-align: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 42px;
  &:hover > .statusText {
    opacity: 1;
    transition: 0.15s;
  }
}
