@import "../../../../assets/scss/utils/colors";

.medical-items {
  padding:0;
  margin:0;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__holder {
    display: flex;
    padding-top: 30px;
    flex-wrap: wrap;
    border-left: 3px solid rgb(141, 141, 141);
    margin-left:40px;
    @media screen and (min-width: 1200px) {
    }
    &--bordered {
      border-bottom: 2px solid $bg-color_primary;
      width: 80%;
    }
  }
  &__items-group-heading {
    padding-bottom: 20px;
    text-align: center;
  }
  &__items-group-item {
    padding-bottom: 10px;
    margin-right: 10px;
    margin-right: 10px;
  }
}
