$border-box-color: $inputs-border-color;

.thermometer_box {
  display: inline-flex;
  flex-direction: column;
  border: 2px solid $border-box-color;
  border-radius: 5px;
  font-size: 12px;
  transform: translateX(-16px);
}
.thermometer_item {
  position: relative;
  &:last-child {
    .thermometer_item_cell {border-radius: 0 0 3px 3px; }
  }
  &:first-child {
    .thermometer_item_cell {border-radius: 3px 3px 0 0; }
  }
  &:not(:last-child) {
    .thermometer_item_cell { border-bottom: 1px solid $black; }
  }
}
.thermometer_item_cell {
  height: 34px;
  width: 34px;
  overflow: hidden;
  cursor: pointer;
  &.color-3 { background: $thermometer-color-orange }
  &.color-2 { background: $thermometer-color-yellow }
  &.color-1 { background: $thermometer-color-green }
  &.color-red { background: $thermometer-color-orange }
  &.color-yellow { background: $thermometer-color-yellow }
  &.color-green { background: $thermometer-color-green }
  &.content {
    &:after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3D(-50%, -115%, 0);
      min-width: 100px;
      padding: 5px;
      color: #fff;
      background: #000;
      font-size: 12px;
      font-weight: 600;
      display: none;
      text-align: center;
      z-index: 10;
    }
    &:hover {
      &:after { display: block; }
    }
  }
}
.thermometer_item_title {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(10%, -50%);
  padding: 3px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
}
.arrow_box {
  border: 1px solid #e3e3e3;
  background: #fff;
  &:before,
  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }
  &:after {
    border-color: rgba(32, 22, 22, 0);
    border-right-color: #fff;
    border-width: 6px;
    margin-top: -6px;
  }
  &:before {
    border-color: rgba(227, 227, 227, 0);
    border-right-color: #e3e3e3;
    border-width: 7px;
    margin-top: -7px;
  }
}
