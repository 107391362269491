.dashboard_meetings_box {
  .title {
    color: $text-color_primary;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .local-title {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.dashboard_scheduler_dates-selector {
  border-bottom: 1px solid $border-color_primary;
  padding: 10px 16px;
  position: relative;
  .dates_range {
    li {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    span {
      display: inline-block;
      max-width: 40px;
    }
  }
}

.dashboard_scheduler_year-selector {
  padding: 8px 0;
  .btns-holder {
    flex: 0 0 75px;
    min-width: 75px;
    position: relative;
  }
}

.dashboard_scheduler_box {
  .scheduler_table-wrapper {
    max-height: 490px;
  }
}

.dashboard_calendar_box {
  margin: 14px 0 24px;
}

.dashboard_calendar_legend {
  margin-bottom: 38px;
}

.dashboard_calendar_patients-list {
  table {
    font-size: 14px;
    color: $text-color_primary;
    td + td {
      color: #828282;
      text-align: right;
    }
  }
  .user-icon {
    fill: $text-color_primary;
    margin-right: 6px;
    height: 10px;
    width: 10px;
  }
}
