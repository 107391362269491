@import "../../assets/scss/utils/mixins";

.react-tel-input {
  .patient-input-field {
    @include common-input-field();
    border: 1px solid $inputs-border-color;
  }
  .patient-country-button {
    border-radius: 4px;
    border: 0;
    top: 1px;
    bottom: 1px;
    left: 1px;
  }
  .patient-list-dropdown {
    border-radius: 4px;
    top: calc(100% + 8px);
  }
}
