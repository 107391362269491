@mixin landing-tile {
  margin: 30px 0;
  border-radius: 50px;
  background: $white;
  height: 280px;
  width: 280px;
  min-width: 280px;
  border: 1px solid $dark-blue;
}

.landing-tile {
  @include landing-tile;
  h2 {
    font-size: 20px;
  }
  @media screen and (min-width: 400px) {
    height: 300px;
    width: 300px;
    min-width: 300px;
  }
  @media screen and (min-width: 768px) {
    margin: 0 5%;
  }
  @media screen and (min-width: 992px) {
    height: 350px;
    width: 350px;
    min-width: 350px;
  }
  &--mobile {
    @include landing-tile;
    @media screen and (min-width: 400px) {
      height: 350px;
      width: 350px;
      min-width: 350px;
    }
    @media screen and (min-width: 992px) {
      margin: 0 5%;
    }
  }
}

.landing-holder {
  flex-direction: column;
  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
  &--mobile {
    flex-direction: column;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
  }
}

.landing-tile_image-wrapper {
  height: 100px;
  width: 100px;
  @media screen and (min-width: 400px) {
    height: 120px;
    width: 120px;
  }
  @media screen and (min-width: 992px) {
    height: 150px;
    width: 150px;
  }
}

.landing-tile_image {
  display: block;
  width: 100%;
}

.search-results-block {
  background: $white;
  top: 120%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  a {
    display: block;
  }
}

.landing-search-wrapper {
  .search-icon {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.landing-input {
  border-radius: 36px;
  width:75%;
  border: 2px solid $primary-color;
  height: 50px;
  padding-left: 35px;
  padding-right: 10px;
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    white-space: pre-line;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.landing-cta {
  height: 50px;
  font-size: 14px;
}
