.selector_list-block {
  background: $white;
  border: 1px solid $ordinary-grey;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 8px);
  width: auto;
  max-height: 150px;
  overflow-y: auto;
  z-index: 5;
  min-width: 100%;
  font-size: 14px;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  > li {
    font-size: 14px;
    padding: 4px 12px;
    &:hover {
      background: rgba($primary-color_light, 0.2);
      cursor: pointer;
    }
  }
}
