.card-box {
  background: $white;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
  &.full-height {
    height: 100%;
  }
  &:not(.no-borders) {
    border: 1px solid $border-color_primary;
  }
  &:not(.collapse) {
    padding: 10px;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &.shadow {
    box-shadow: 0 2px 8px rgba($black, 0.05);
  }
  .card_avatar {
    border-radius: 50%;
    margin: 0 auto 20px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aligned-wrappers {
  font-size: 14px;
  .item-sm-4 {
    margin-bottom: 6px;
    &:nth-child(1) {
      padding-right: 6px;
    }
    &:nth-child(3n + 2) {
      padding-right: 6px;
    }
    &:nth-child(3n + 4) {
      padding-right: 6px;
    }
  }
}

.box-title {
  color: $text-color_secondary;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  letter-spacing: 0.6px;
  .icon-holder {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $primary-color;
    position: relative;
    margin-right: 12px;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.card_inputs-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  .input-holder {
    margin-bottom: 20px;
  }

}

.card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 0 0 16px;
  border-bottom: 1px solid $inputs-border-color;
  &.is-expanded {


  }
  .title {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .counter {
    background: $text-color_additional;
    border-radius: 8px;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 8px;
  }
}

.card_body {
  // flex: 1 1 0px;
  overflow-y: auto;
}

.card_footer {
  padding: 0 0 0;
  flex: 0 0 auto;
}

.card_section {
  padding: 22px 0 0;
  &:not(:last-child) {
    border-bottom: 1px solid $inputs-border-color;
    padding: 22px 0;
  }
  &--mod {
    padding: 16px 20px;
  }
  &--side {
    padding: 22px 0 11px 0 !important;
  }
}
